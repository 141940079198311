import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { ReservationService } from '../../core/reservation.service';
import { TimeSlot, Day, Problem, Doctor } from '../types/types';
import { cloneDeep } from 'lodash';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss']
})
export class TimeComponent implements OnInit {
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();
  NEXT_DAY_HOUR = 19;

  today = DateTime.local();
  startWeekDate: DateTime | undefined;
  endWeekDate: DateTime | undefined;
  weekDays: Day[] = [];
  isDisabledNextDay: boolean = false;
  problem: Problem = {} as Problem;
  hours: number[] = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

  selectedDate: DateTime | undefined;
  selectedTime: number | undefined;
  selectedDoctor: Doctor | undefined;
  availableDoctors: Doctor[] = [];

  eventList: any[] = [];
  filteredEvents: any[] = [];

  isLoading = true;

  constructor(private reservationService: ReservationService, private gaService: GoogleAnalyticsService) {
  }

  ngOnInit(): void {
    this.startWeekDate = DateTime.local().startOf('week');
    this.endWeekDate = DateTime.local().endOf('week');

    if (this.today.weekday >= 5 || (this.today.weekday === 4 && this.today.hour >= this.NEXT_DAY_HOUR)) {
      this.startWeekDate = this.startWeekDate.plus({week: 1});
      this.endWeekDate = this.endWeekDate.plus({week: 1});
    }

    if (this.today.hour >= this.NEXT_DAY_HOUR) {
      this.isDisabledNextDay = true;
    }

    this.reservationService.problemSubject.subscribe(resp => {
      this.problem = resp;
      this.calculateWeekDays();
      this.getCalendarEvents();
    });
  }

  calculateWeekDays() {
    this.weekDays = [];

    const timeSlots: TimeSlot[] = [];

    this.hours.forEach(hour => {
      timeSlots.push({
        hour: hour,
        disabled: !(this.problem.doctor?.length > 0),
        doctor: this.problem.doctor
      })
    });

    let i = 0;
    while (i < 5 && this.startWeekDate) {
      const day = this.startWeekDate.plus({days: i});
      this.weekDays.push({
        day,
        time: cloneDeep(timeSlots)
      });
      i++;
    }
  }

  showCurrentWeek() {
    this.isLoading = true;
    this.startWeekDate = DateTime.local().startOf('week');
    this.endWeekDate = DateTime.local().endOf('week');
    this.calculateWeekDays();
    this.getCalendarEvents();
  }

  showPreviousWeek() {
    this.isLoading = true;
    this.startWeekDate = this.startWeekDate?.plus({days: -7});
    this.endWeekDate = this.endWeekDate?.plus({days: -7});
    this.calculateWeekDays();
    this.getCalendarEvents();
  }

  showNextWeek() {
    this.isLoading = true;
    this.startWeekDate = this.startWeekDate?.plus({days: 7});
    this.endWeekDate = this.endWeekDate?.plus({days: 7});
    this.calculateWeekDays();
    this.getCalendarEvents();
  }

  selectTime(date: DateTime, timeSlot: TimeSlot) {
    if (timeSlot.disabled) {
      return;
    }

    this.selectedDate = date;
    this.selectedTime = timeSlot.hour;
    this.availableDoctors = timeSlot.doctor;
    this.selectDoctor(timeSlot.doctor[0].email);
  }

  selectDoctor(doctorEmail?: string) {
    this.selectedDoctor = this.availableDoctors.find(doctor => doctor.email === doctorEmail) || new Doctor();
    this.reservationService.doctorSubject.next(this.selectedDoctor);
  }

  clickNext() {
    // this.gaService.event('select_time', 'reservation', `${this.selectedDate} - ${this.selectedTime}`);
    this.next.emit();
    this.reservationService.timeSubject.next(this.selectedDate?.set({hour: this.selectedTime, minute: 0})!!);
  }

  clickBack() {
    this.selectedDate = undefined;
    this.selectedTime = undefined;
    this.selectedDoctor = undefined;
    this.availableDoctors = [];
    // this.gaService.event('select_time_back', 'reservation');
    this.back.emit();
  }

  getCalendarEvents() {
    this.isLoading = true;
    this.reservationService.getEvents(this.startWeekDate!!, this.endWeekDate!!).subscribe({
      next: resp => this.filterEvents(resp),
      error: err => console.log(err)
    });
  }

  async filterEvents(events: any) {
    this.eventList = events.filter((event: any) => event.status !== 'cancelled');

    if(this.problem.doctor?.length > 0) {
      this.filteredEvents = this.eventList
        .filter(event => this.problem.doctor.find(doc => {
          const pendingDoctor = this.getEventDescriptionDoctorEmail(event)
          return doc.email === event.creator.email || doc.email === pendingDoctor
        }));

      this.filterTimeSlots();
    } else {
      this.isLoading = false;
    }
  }

  filterTimeSlots() {
    this.isLoading = true;
    // because of events
    this.filteredEvents.forEach(event => {
      const startEvent = DateTime.fromISO(event.start.dateTime || event.start.date, {zone: event.start?.timeZone});
      const endEvent = DateTime.fromISO(event.end.dateTime || event.end.date, {zone: event.start?.timeZone});
      let disabledTimeSlots: number[] = [];

      if (!startEvent.hour) {
        disabledTimeSlots = [...this.hours];
      }


      let i = startEvent.hour;
      while (i <= endEvent.hour && startEvent.hour !== 0) {
        if (i !== endEvent.hour || i === endEvent.hour && endEvent.minute !== 0) {
          disabledTimeSlots.push(i);
        }
        i++;
      }

      const day = this.weekDays.find(day => day.day.weekday === startEvent.weekday);
      disabledTimeSlots.forEach(hour => {
        const timeSlot = day?.time.find(time => time.hour === hour);
        const pendingDoctor = this.getEventDescriptionDoctorEmail(event);

        if (timeSlot) {
          timeSlot.doctor = timeSlot?.doctor.filter(doc => doc.email !== event.creator.email && doc.email !== pendingDoctor);
        }

        if (timeSlot && timeSlot.doctor && timeSlot.doctor.length === 0) {
          timeSlot.disabled = true;
        }
      });
    });

    // because of today or before or if today later than 19:00 tomorrow disabled
    const untilDay = DateTime.local().hour >= this.NEXT_DAY_HOUR ? this.today.plus({day: 1}) : this.today;
    const todayOrBeforeTimeSlot = this.weekDays.filter(day => day.day.toISODate() <= untilDay.toISODate());
    todayOrBeforeTimeSlot.forEach(day => day.time.map(time => time.disabled = true));

    this.isLoading = false;
  }

  private getEventDescriptionDoctorEmail(event: any): string {
    let email = '';
    try {
      email = event.description ? JSON.parse(event?.description).doctor.email : '';
    } catch (e) {
    }

    return email;
  }
}
